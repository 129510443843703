// Handle Advanced Custom Field's (WordPress Plugin) link field type.

import React from "react";
import Link from "./link";

const CmsLink = ({ link, arrow, children, ...other }) => {
  if (link === null) return "";
  let finalUrl = link.url

  if (!link.url.includes('.pdf')) {
    finalUrl = finalUrl.includes(process.env.WP_URL)
      ? finalUrl.replace(process.env.WP_URL, "")
      : finalUrl;
  } else {
    finalUrl = finalUrl.includes(process.env.GATSBY_URL) ? finalUrl.replace(process.env.GATSBY_URL, "") : finalUrl;
    finalUrl = finalUrl.includes(process.env.WP_URL) ? finalUrl : process.env.WP_URL + finalUrl;
  }

  if (link.target && !other["target"]) other["target"] = link.target;
  return (
    <Link to={finalUrl} {...other}>
      {children ? children : link.title}
    </Link>
  );
};

export default CmsLink;
