import React, { useRef } from "react";
import formatWpMenu from "../../utilities/format-wp-menu";
import { useStaticQuery, graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";
import Link from "../utilities/link";
import Content from "../utilities/content";
import CmsLink from "../utilities/cms-link";

const Header = () => {
  const {
    wp: {
      themeSettings: { themeSettings: data },
    },
    primaryNav: {
      menuItems: { nodes: primaryNavLinks },
    },
    secondaryNav: {
      menuItems: { nodes: topNavLinks },
    },
  } = useStaticQuery(
    graphql`
      query Header {
        wp {
          themeSettings {
            themeSettings {
              logo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 292
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      quality: 100
                    )
                  }
                  extension
                  publicURL
                }
              }
              pages {
                fieldGroupName
                content
                contentImageLink {
                  target
                  title
                  url
                }
                pageParent {
                  ... on WpPage {
                    id
                    uri
                    slug
                    title
                  }
                  ... on WpPost {
                    id
                    uri
                    slug
                    title
                  }
                }
                pageChild {
                  fieldGroupName
                  page {
                    target
                    title
                    url
                    # ... on WpPage {
                    #   id
                    #   uri
                    #   slug
                    #   title
                    # }
                    # ... on WpPost {
                    #   id
                    #   uri
                    #   slug
                    #   title
                    # }
                  }
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                      extension
                      publicURL
                    }
                  }
                  text
                }
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                    extension
                    publicURL
                  }
                }
              }
            }
          }
        }
        primaryNav: wpMenu(slug: { eq: "primary-menu" }) {
          menuItems {
            nodes {
              id
              label
              path
              parentId
              childItems {
                nodes {
                  id
                  label
                  url
                  path
                  childItems {
                    nodes {
                      id
                      label
                      url
                      path
                    }
                  }
                }
              }
            }
          }
        }

        secondaryNav: wpMenu(slug: { eq: "top-menu" }) {
          menuItems {
            nodes {
              id
              label
              path
              parentId
              childItems {
                nodes {
                  id
                  label
                  url
                  path
                  childItems {
                    nodes {
                      id
                      label
                      url
                      path
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const primaryNav = formatWpMenu(primaryNavLinks),
    header = useRef(null);
  const secondaryNav = formatWpMenu(topNavLinks);

  const toggleNavClass = () => {
    header.current.classList.toggle("show");
  };
  const toggleNav = (e, href) => {
    if (href) {
      const internal = /^\/(?!\/)/.test(href);
      if (!internal) {
        return;
      }
    }
    e.preventDefault();
    toggleNavClass();
  };

  const NavImage = ({ item, className }) => (
    <>
      <div className={`nav-image-header ${className}`}>
        <Content className="mb-0">{item.content}</Content>
      </div>
      <CmsImage
        className="img-fluid"
        image={item.image}
        style={{ width: "100%" }}
      />
    </>
  );

  return (
    <>
      <header className="header">
        <div className="header-top d-none d-lg-block">
          <div className="container">
            <div className="d-flex justify-content-lg-end header-top-nav">
              <ul className="nav bg-secondary">
                {secondaryNav != null &&
                  secondaryNav.map((link) => (
                    <li key={link.id} className="nav-item">
                      <Link className="nav-link" to={link.path} target="_blank">
                        {link.label}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <div className="w-100 d-flex justify-content-between">
              <Link className="navbar-brand" to="/">
                <CmsImage image={data.logo} />
              </Link>
              <button
                className="navbar-toggler btn-menu"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mainNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="btn-menu__bars" aria-hidden="true"></i>
              </button>
            </div>
            <div className="w-100 mt-2 header-nav">
              <div
                className="collapse navbar-collapse"
                ref={header}
                id="mainNav"
              >
                <ul className="navbar-nav ms-auto d-none d-lg-flex">
                  {primaryNav &&
                    primaryNav.map((link) => {
                      return link.childItems.nodes.length !== 1 ? (
                        <li
                          key={link.id}
                          className="nav-item dropdown has-megamenu"
                        >
                          <div
                            className="nav-link dropdown-toggle"
                            role="button"
                            data-bs-toggle="dropdown"
                          >
                            {link.label}
                          </div>
                          <div
                            className="dropdown-menu megamenu"
                            role="menu"
                            aria-labelledby=""
                          >
                            <div className="container">
                              <div className="megamenu-content">
                                <div className="row">
                                  {link.childItems.nodes.length !== 0 &&
                                    link.childItems.nodes.map(
                                      (childItem, i) => (
                                        <>
                                          <div
                                            key={childItem.id}
                                            className={
                                              i === 0 ? "col-lg-2" : "col-lg-5"
                                            }
                                          >
                                            <Link
                                              to={childItem.path}
                                              className="text-decoration-none megamenu-header"
                                            >
                                              <h5>{childItem.label}</h5>
                                            </Link>
                                            <ul
                                              className={`list-unstyled ${
                                                childItem.childItems.nodes
                                                  .length <= 5
                                                  ? "columnCount1"
                                                  : "columnCount2"
                                              }`}
                                            >
                                              {data.pages &&
                                                data.pages.map(
                                                  (item) =>
                                                    item.pageParent?.uri ===
                                                      childItem.path &&
                                                    item.pageChild !== null &&
                                                    item.pageChild.map(
                                                      (i, key) => (
                                                        <li key={i}>
                                                          {i.page !== null ? (
                                                            <CmsLink
                                                              className="nav-link py-0"
                                                              link={i.page}
                                                            >
                                                              <CmsImage
                                                                className="nav-brands-icon pt-2"
                                                                image={i.image}
                                                              />
                                                              <span className="nav-brands-text">
                                                                {i.text}
                                                              </span>
                                                            </CmsLink>
                                                          ) : (
                                                            <>
                                                              <CmsImage
                                                                className="nav-brands-icon pt-2"
                                                                image={i.image}
                                                              />
                                                              <span className="nav-brands-text">
                                                                {i.text}
                                                              </span>
                                                            </>
                                                          )}
                                                        </li>
                                                      )
                                                    )
                                                )}

                                              {childItem.childItems.nodes &&
                                                childItem.childItems.nodes.map(
                                                  (cItem) => (
                                                    <li
                                                      key={cItem.id}
                                                      className="nav-item"
                                                    >
                                                      <Link
                                                        className="nav-link"
                                                        to={cItem.path}
                                                      >
                                                        {cItem.label}
                                                      </Link>
                                                    </li>
                                                  )
                                                )}
                                            </ul>
                                          </div>

                                          {data.pages &&
                                            data.pages.map((item, i) => {
                                              return (
                                                childItem.path ===
                                                  item.pageParent?.uri &&
                                                item.image && (
                                                  <div key={i} className="col-lg-5 order-last">
                                                    <div className="nav-image">
                                                      <div>
                                                        {item.contentImageLink ? (
                                                          <CmsLink
                                                            link={
                                                              item.contentImageLink
                                                            }
                                                          >
                                                            <NavImage
                                                              className="x"
                                                              item={item}
                                                            />
                                                          </CmsLink>
                                                        ) : (
                                                          <NavImage
                                                            className="x"
                                                            item={item}
                                                          />
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              );
                                            })}
                                        </>
                                      )
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ) : (
                        <li key={link.id} className="nav-item dropdown">
                          <div
                            className="nav-link dropdown-toggle"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {link.label}
                          </div>
                          <div className="dropdown-menu dropdown-menu-end">
                            {link.childItems.nodes &&
                              link.childItems.nodes.map((childItem, i) => (
                                <div key={i}>
                                  <Link
                                    className="text-decoration-none"
                                    to={childItem.path}
                                  >
                                    <h5>{childItem.label}</h5>
                                  </Link>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <ul className="nav flex-column">
                                        {childItem.childItems.nodes &&
                                          childItem.childItems.nodes.map(
                                            (childItem) => (
                                              <li
                                                key={childItem.id}
                                                className="nav-item"
                                              >
                                                <Link
                                                  className="nav-link"
                                                  to={childItem.path}
                                                >
                                                  {childItem.label}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                      </ul>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="nav-image">
                                        {data.pages &&
                                          data.pages.map((item, i) => {
                                            let childItemPath = childItem.path.split("/").filter(Boolean);
                                            return (
                                              item.pageChild === null &&
                                              (childItem.path === item.pageParent?.uri || childItemPath[childItemPath.length - 1] === item.pageParent?.slug) && (
                                                <div key={i}>
                                                  {item.contentImageLink ? (
                                                    <CmsLink
                                                      link={
                                                        item.contentImageLink
                                                      }
                                                    >
                                                      <NavImage item={item} />
                                                    </CmsLink>
                                                  ) : (
                                                    <NavImage item={item} />
                                                  )}
                                                </div>
                                              )
                                            );
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </li>
                      );
                    })}
                </ul>
                <ul className="navbar-nav ms-auto d-flex d-lg-none">
                  {primaryNav &&
                    primaryNav.map((link) => (
                      <li key={link.id} className="nav-item dropdown">
                        <div
                          className="nav-link dropdown-toggle"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {link.label}
                        </div>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <>
                            <li onClick={toggleNav}>
                              <Link to={link.path} className="dropdown-item">
                                {link.label}
                              </Link>
                            </li>
                            {link.childItems.nodes &&
                              link.childItems.nodes.map((child) => {
                                return child.childItems.nodes.map(
                                  (sub, key) => (
                                    <li key={key} onClick={e => toggleNav(e, sub.path)}>
                                      <Link
                                        to={sub.path}
                                        className="dropdown-item"
                                      >
                                        {sub.label}
                                      </Link>
                                    </li>
                                  )
                                );
                              })}
                          </>
                        </ul>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
