import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import formatWpMenu from "../../utilities/format-wp-menu";
import CmsImage from "../utilities/cms-image";
import Content from "../utilities/content";
import Link from "../utilities/link";

// @TODO Reduce commented code below if appropriate.

const Footer = () => {
  const {
    wp: {
      themeSettings: { themeSettings: data },
    },
    footerNav: {
      menuItems: { nodes: footerNavLinks },
    },
  } = useStaticQuery(graphql`
    query Footer {
      wp {
        themeSettings {
          themeSettings {
            copyright
            content
            footerSocialMedia {
              fieldGroupName
              socialMediaName
              socialMediaUrl
            }
            centerLogo {
              fieldGroupName
              link {
                target
                title
                url
              }
              logo {
                altText
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            leftLogo {
              altText
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      footerNav: wpMenu(slug: { eq: "footer-menu" }) {
        menuItems {
          nodes {
            id
            label
            path
            parentId
            childItems {
              nodes {
                id
                label
                url
                path
                childItems {
                  nodes {
                    id
                    label
                    url
                    path
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const year = new Date().getFullYear();
  data.copyright = data.copyright.replace(/{{ year }}/, year.toString());
  const footerNav = formatWpMenu(footerNavLinks);

  const cookieLink = function (e) {
    e.preventDefault();
    window.displayPreferenceModal();
    return false;
  };

  return (
    <footer className="footer">
      <div className="footer-background d-flex justify-content-between">
        <div></div>
        <div></div>
      </div>
      <div className="container">
        <div className="footer-top">
          <div className="row">
            <div className="col-12">
              <div className="d-none d-lg-block">
                <div className="row">
                  <ul className="column-count-lists-footer">
                    {footerNav &&
                      footerNav.map((link) => (
                        <li key={link.id}>
                          <Link to={link.path} className="text-decoration-none">
                            <h5 className="text-primary text-uppercase">
                              {link.label}
                            </h5>
                          </Link>
                          <ul className="nav flex-column">
                            {link.childItems.nodes &&
                              link.childItems.nodes.map((item, i) => (
                                <li key={item.id} className="nav-item">
                                  <Link to={item.path} className="nav-link">
                                    {item.label}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>

              {/* mobile */}
              {/* <div className="d-block d-lg-none">
                <ul className="nav flex-column footer-mobile-nav">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-expanded="false"
                    >
                      Sports
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-expanded="false"
                    >
                      Technology
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-expanded="false"
                    >
                      About
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-expanded="false"
                    >
                      Contact
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Astroturf TV
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Projects
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      News
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Careers
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 text-center text-lg-start">
                <Content className="content-footer-contact-info mb-4 mb-lg-2">
                  {data.content}
                </Content>
                <CmsImage
                  className="img-fliud mb-3 mb-lg-0"
                  image={data.leftLogo}
                />
              </div>
              <div className="col-lg-4 text-center mb-4 mb-lg-0">
                {data.centerLogo && (
                  <ul
                    className="d-flex flex-column flex-sm-row flex-lg-column flex-xl-row align-items-center justify-content-center mb-0 ps-0"
                    style={{ gap: "1rem" }}
                  >
                    {data.centerLogo.map((item, i) => {
                      return item.link !== null ? (
                        <li
                          key={item.id}
                          className="d-flex align-items-center d-flex justify-content-center"
                        >
                          <Link
                            to={item.link.url}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <CmsImage
                              key={i}
                              className="img-fliud d-block"
                              image={item.logo}
                            />
                          </Link>
                        </li>
                      ) : (
                        <li
                          key={item.id}
                          className="d-flex align-items-center d-flex justify-content-center"
                        >
                          <CmsImage
                            key={i}
                            className="img-fliud d-block"
                            image={item.logo}
                          />
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              <div className="col-lg-4 text-center text-lg-end">
                <ul className="nav justify-content-center justify-content-lg-end mb-4 mb-lg-2 footer-bottom-social">
                  {data.footerSocialMedia &&
                    data.footerSocialMedia.map((md, key) => (
                      <li key={md.id} className="nav-item">
                        <a
                          href={md.socialMediaUrl}
                          className="nav-link"
                          target="_blank"
                        >
                          <FontAwesomeIcon
                            icon={[
                              "fab",
                              `${md.socialMediaName.toLowerCase()}`,
                            ]}
                          />
                        </a>
                      </li>
                    ))}
                </ul>
                <Content className="text-primary footer-copyright">
                  {data.copyright}
                </Content>
                <div className="privacy-links footer-copyright">
                  <p>
                    <a
                      href="#"
                      onClick={(e) => cookieLink(e)}
                      id="termly-consent-preferences"
                    >
                      Consent Preferences
                    </a>
                    &nbsp;|&nbsp;
                    <a href="/terms-conditions">Terms & Conditions</a>
                  </p>
                  <p>
                    <a
                      href="https://app.termly.io/notify/e3c5aee1-e778-469c-b20b-db90e49f29b0"
                      target="_blank"
                    >
                      Do Not Sell or Share My Personal information
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
