// Display WYSIWYG Content

import React from "react";

const Content = ({ className, children, ...other }) => {
  return (
    <>
      {children && (
        <div
          className={`content content-main ${className ? className : ""}`}
          dangerouslySetInnerHTML={{ __html: children }}
          {...other}
        />
      )}
    </>
  );
};

export default Content;
